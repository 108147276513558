import React from "react";
import {
  StyledButton,
  StyledPromotore,
  StyledPromotoreContent,
  StyledPromotoreImage,
} from "./style";
import rocketSrc from "../../../static/2020/images/impactcall/rocket.png";

export const Promotore = () => (
  <StyledPromotore id="promotore">
    <StyledPromotoreContent>
      <h2>Il promotore</h2>
      <p>
        3Bee ® punto di riferimento della sostenibilità, è la startup agritech che fa dello sviluppo di tecnologie
        innovative il cavallo di battaglia per la tutela del nostro pianeta. 
      </p>
      <p>
        In virtù di questa sua forte anima ha sentito la necessità di
        rappresentare tutti i suoi obiettivi sotto un unico termine:
        “impactability”: l’impact sustainability.
      </p>
      <p>
        Grazie al suo lavoro e all’esperienza maturata sul campo, è stata in
        grado di creare un vasto network capace di contribuire in maniera
        concreta alla sua vision: il supporto delle api, degli apicoltori e
        della biodiversità.{" "}
      </p>
      <StyledButton href="/">Visita il sito 3Bee</StyledButton>
    </StyledPromotoreContent>
    <StyledPromotoreImage src={rocketSrc} loading="lazy" />
  </StyledPromotore>
);
